// Footer
.footer {
  color: $lighter-grey;
  background: $dark-blue-grey;
}

.footer-content-blocks {
  padding-left: 15px;
  padding-right: 15px;
  @include breakpoint(m) {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
}
.footer-content-block {
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 1px solid $grey;
  @include breakpoint(m) {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
  }
  &:first-child {
    border-top-color: transparent;
    padding-top: 0;
  }
}

.footer-copyright {
  @include font-size($base-font-size - 2);
  @include line-height($base-line-height - 2);
  color: $grey;
  background: $darker-blue-grey;
  padding-top: .8em;
  padding-bottom: .8em;
  p { margin: 0; }
}

// Contact List
.contact-list {
  list-style: none;
  margin: 0;
  padding: 0;
  & > li {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}