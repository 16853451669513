// Website Variables and Colors
$base-background-color: #fff;
$base-font-family: "Ubuntu", sans-serif;
$base-font-size: 14;
$base-line-height: 22;
$base-font-weight: 400;
$base-font-color: #828282;
$base-link-color: #2cc7c1;
$base-link-hover-color: #2cc7c1;

// Headings
$base-heading-font-family: "Montserrat", sans-serif;
$base-h1-font-size: 32;
$base-h1-line-height: 38;
$base-h1-font-weight: 400;
$base-h1-color: #828282;
$base-h2-font-size: 26;
$base-h2-line-height: 34;
$base-h2-font-weight: 400;
$base-h2-color: #828282;
$base-h3-font-size: 22;
$base-h3-line-height: 28;
$base-h3-font-weight: 400;
$base-h3-color: #828282;
$base-h4-font-size: 18;
$base-h4-line-height: 24;
$base-h4-font-weight: 400;
$base-h4-color: #828282;
$base-h5-font-size: 16;
$base-h5-line-height: 22;
$base-h5-font-weight: 400;
$base-h5-color: #828282;
$base-h6-font-size: 14;
$base-h6-line-height: 20;
$base-h6-font-weight: 400;
$base-h6-color: #828282;

// Blockquotes
$base-blockquote-font-family: sans-serif;
$base-blockquote-font-size: 22;
$base-blockquote-line-height: 28;
$base-blockquote-color: #424242;
$base-blockquote-cite-color: #777;

// Code
$base-code-font-family: 'courier new', monospace, serif;
$base-code-font-size: 13;
$base-code-line-height: 18;
$base-code-color: #272727;
$base-code-background-color: #f1f1f1;
$base-code-border-color: #d7d7d7;

// Forms
$base-placeholder-color: #828282;
$base-input-placeholder-color: #999;
$base-input-color: #525b64;
$base-input-background-color: #ececec;
$base-input-background-focus-color: #f1f1f1;
$base-input-border-color: transparent;
$base-input-border-focus-color: #2cc7c1;


// Colours
$white: #fff;
$black: #000;

$red: #f8504d;
$dark-red: #f53a37;
$teal: #2cc7c1;

$smoke: #cacaca;

$copy-grey: #828282;

$grey: #ccc;
$light-grey: #ececec;
$lighter-grey: #f3f3f3;

$dark-blue-grey: #525b64;
$darker-blue-grey: #3f464c;